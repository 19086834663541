import Menu from "./Menu";
import anc_plain from "./anc.svg";  // Importing the SVG file

export default function SideBar({ loading, error, refetch }) {
    return (
        <>
            <img className="pt-6 pl-6" src={anc_plain} alt="ANC Logo" width="190" />
            <Menu loading={loading} error={error} refetch={refetch} />
        </>
    );
}
