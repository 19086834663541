export default function Title({previewCount}) {
    return (
        <div className="level-left">
            <div className="level-item">
                <h1 className="title has-text-weight-normal is-4">
                    All Datasets ({previewCount})
                </h1>
            </div>
        </div>
    );
}