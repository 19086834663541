import 'bulma/css/bulma.min.css';
import SideBar from './SideBar/SideBar';
import Main from './Main/Main';
import { useQuery } from '@apollo/client';
import { GET_DATA } from "./gql/Query";
// import Footer from './SideBar/Footer';



export default function App() {
  const { loading, error, data, refetch } = useQuery(GET_DATA);
  let previewCount = 0;

  if (loading) {
    // Data is still loading
  } else if (error) {
    // Error occurred while fetching data
    console.log(error);
  } else {
    // Data has been fetched successfully
    previewCount = data.datasets.length;
  }
  return (
    <main>
      <section>
      <div className="columns">
          <div className="column is-one-quarter has-background-info pr-6 pb-6">
          <SideBar 
              loading={loading}
              error={error}
              refetch={refetch}
          />
        </div>
        <div className="column">
          <Main
            previewCount={previewCount}
            loading={loading}
            error={error}
            data={data}
            refetch={refetch}
          />
        </div>
      </div>
      </section>
       <section>
        <div className="columns">
          <div className="column is-one-quarter has-background-info pr-6">
            {/* <Footer /> */}
          </div>
        </div>
      </section>
    </main>
  );
}

