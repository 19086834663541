import PreviewList from "./Preview";
// import SearchBarAndQButton from "./SearchBar/SearchBarAndQButton";
import Title from "./Title";


export default function Main({ loading, error, data, previewCount, refetch }) {
    return (
        <> 
            {/* <section className="section py-6">
                <div className="columns">
                    <div className="column is-half">
                        
                    </div>
                    <div className="column">
                        <SearchBarAndQButton 
                            loading={loading}
                            error={error}
                            refetch={refetch}
                        />
                    </div>
                </div>
            </section> */}
            <section className="section">
                <div className="level">
                    <Title
                        previewCount={previewCount}
                    />
                </div>
                <div className="tile is-ancestor is-vertical">
                    <PreviewList
                        loading={loading}
                        error={error}
                        data={data}
                    />
                </div>
            </section>
        </>
    );
}