import { gql } from "@apollo/client";

export const GET_DATA = gql`
    query Datasets($where: DatasetWhere) {
        datasets(where: $where) {
            name
            authors {
                id
            }
        }
    }
`;
