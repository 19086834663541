export default function Menu({ loading, error, refetch }) {
    return (
        <aside className="menu py-6 pl-6">
            <Filtergroup
                title={"About Dataset"}
                properties={{ one: "name", two: "id" }}
                loading={loading}
                error={error}
                refetch={refetch}
            />
            {/* <Filtergroup title={"Properties of Participants"} properties={{one: "Sex", two:"Age", three:"Amount", four:"Handedness", five:"Add Filter"}} />
            <Filtergroup title={"Neuro Imaging Data"} properties={{one: "Modality", two:"Datatypes", three:"Preprocessing"}} />
            <Filtergroup title={"Experiment"} properties={{one:"Task", two: "HED"}} /> */}
        </aside>
    );
}

function Filtergroup({ title, properties, loading, error, refetch }) {

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();
        // check if fields are filled and based on that build the obj 'varObject'
        let varObject = {};
        if (myName.value) {
            varObject.name_CONTAINS = e.target.myName.value
        };
        if (myId.value) {
            varObject.authors_SOME = { id_CONTAINS: e.target.myId.value }
        };
        refetch({
            where: varObject
                    });
    };
    

    if (loading) return <div>Loading</div>;
    if (error) return <div>error</div>;

    // This is the code bit which was used to build the menu dynamically based on the property values
    // passed to the component, when activated the components from otherComps.js can be used:

    // const compType = Object.values(properties).map(vals =>
    //     //  <InactiveProperty properties={vals} key={vals}>{properties.vals}</InactiveProperty>

    //     <Input properties={vals} key={vals}
    //         loading={loading}
    //         error={error}
    //         refetch={refetch}
    //     >{properties.vals}
    //     </Input>
    // );

    return (
        <>
            <p className="menu-label has-text-white">
                {title}
            </p>
            <ul className="menu-list">
                <form method="post" onSubmit={handleSubmit}>
                    {/* {compType} */}
                    <li><a className="is-active has-background-info-dark">Title</a>
                        <ul>
                            <li>
                                <div className="field">
                                    <p className="control has-icons-right">
                                        <input name="myName" defaultValue="" type="string" id="myName"
                                            className="input is-small is-rounded is-info is-light" placeholder="search for title..."
                                        />
                                        <span className="icon is-right pr-3">
                                            <ion-icon name="search-outline" size="small"></ion-icon>
                                        </span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li><a className="is-active has-background-info-dark">Author</a>
                        <ul>
                            <li>
                                <div className="field">
                                    <p className="control has-icons-right">
                                        <input name="myId" defaultValue="" type="string" id="myId"
                                            className="input is-small is-rounded is-info is-light" placeholder="search for author..."
                                        />
                                        <span className="icon is-right pr-3">
                                            <ion-icon name="search-outline" size="small"></ion-icon>
                                        </span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <button className="button is-small is-info is-light ml-5 my-4" type="reset">Clear</button>
                    <button className="button is-small is-info is-light ml-3 my-4" type="submit">Search</button>
                </form> 
            </ul>
        </>
    );
}
