import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// import "./styles.css";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

const client = new ApolloClient({
  uri: 'https://neurosemmel.anc.plus.ac.at/graphql/',
  cache: new InMemoryCache()
});


import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
  <StrictMode>
    <App />
  </StrictMode>
  </ApolloProvider>,
);