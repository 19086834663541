export default function PreviewList({ loading, error, data }) {

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!data || !data.datasets) {
        return <div>No data available.</div>;
    }

    const previews = data.datasets.map(dataset =>
         <Preview {...dataset} key={dataset.name} />
     );

    return previews;
}


function Preview({...dataset}) {
    return (
        <div className="tile box">
            <div className="tile is-vertical">
                <div className="tile">
                    <div className="tile is-parent is-vertical is-6">
                        <div className="tile">
                            <article className="tile is-child">
                                <TitleID
                                    title={dataset.name}
                                    id={dataset.id}
                                />
                            </article>
                        </div>
                        <div className="tile">
                            <article className="tile is-child">
                                {/* <Aquisition
                                    aquisition={dataset.aquisition}
                                    update={dataset.update}
                                    curator={dataset.curator}
                                /> */}
                            </article>
                        </div>
                        <article className="tile is-child "></article>
                        <article className="tile is-child ">
                            {/* <Modality modalities={dataset.modalities} /> */}
                        </article>
                        <article className="tile is-child ">
                            {/* <Task tasks={dataset.tasks} /> */}
                        </article>
                        <article className="tile is-child ">
                            {/* <HEDTag heds={dataset.heds} /> */}
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}


function TitleID({ title = "Title of Dataset", id = "xxxx" }) {
    return (
        <p className="title has-text-weight-light">{title}{/*; ID: {id}*/}</p>
    );
}


function Aquisition({ aquisition = "xx/xx/xx", update = "xx/xx/xx", curator = "Maxine Muster" }) {
    return (
        <p className="subtitle is-size-6">aquisition {aquisition} | update {update}, by {curator}</p>
    );
}

function Modality({ modalities = true }) {

    const modality = Object.entries(modalities).map(([kys, vals]) =>
        vals && (<span className="tag is-info is-light" key={kys}>{kys}</span>
    ));
    

    return (
        <div className="field is-grouped py-3">
            <p>Modality</p>
            <div className="tags pl-2">
                {modality}
            </div>
        </div>
    );
}

function Task({ tasks = "" }) {
    const task = Object.values(tasks).map(vals =>
    <span className="tag is-info is-light" key={vals}>{vals}</span>
    );

    return (
        <div className="field is-grouped py-3">
            <p>Task</p>
            <div className="tags pl-2">
                {task}
                <span className="tag is-info is-light">...</span>
            </div>
        </div>
    );
}

function HEDTag({ heds = "" }) {
    const hed = Object.values(heds).map(vals =>
    <span className="tag is-info is-light" key={vals}>{vals}</span>
    );

    return (
        <div className="field is-grouped py-3">
            <p>HED-Tag</p>
            <div className="tags pl-2">
                {hed}
                <span className="tag is-info is-light">...</span>
            </div>
        </div>
    );
}